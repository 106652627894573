import {
  FieldValue,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import editorDatabase, {
  ADMIN_USERS_COLLECTION,
  SHORTBREAD_ARTISTS_COLLECTION,
  USERS_COLLECTION,
} from "./firebaseClientConfig";

export interface User {
  email: string;
  uid?: string;
  credits: number;
  displayName: string;
  createdAt: Date;
  recentlyOpenedComics: string[];
}

export const fetchUserEmailById = async (
  uid: string
): Promise<string | null> => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    return null;
  }

  const userData = userSnapshot.data();
  return userData.email || null;
};

export const fetchCredits = async (uid: string): Promise<number> => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  const userSnapshot = await getDoc(userDocRef);
  return userSnapshot.data()?.credits || 0;
};

export const updateCredits = async (
  uid: string,
  creditsChange: number
): Promise<void> => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  // Get the current credits
  const userSnapshot = await getDoc(userDocRef);
  const currentCredits = userSnapshot.data()?.credits || 0;

  // Calculate new credits
  const newCredits = currentCredits + creditsChange;

  // Ensure credits don't go below zero
  const updatedCredits = Math.max(newCredits, 0);
  await updateDoc(userDocRef, { credits: updatedCredits });
};

export const checkIsShortbreadArtist = async (
  uid: string
): Promise<boolean> => {
  const shortbreadArtistsCollection = collection(
    editorDatabase,
    SHORTBREAD_ARTISTS_COLLECTION
  );
  const userDoc = query(
    shortbreadArtistsCollection,
    where("email", "==", await fetchUserEmailById(uid))
  );
  const userSnapshot = await getDocs(userDoc);
  return userSnapshot.size > 0;
};

export const fetchIsAdminUser = async (userId: string) => {
  const adminUsersCollection = collection(
    editorDatabase,
    ADMIN_USERS_COLLECTION
  );
  const adminUsersDoc = doc(adminUsersCollection, userId);
  const adminUsersSnapshot = await getDoc(adminUsersDoc);
  const user = await getDoc(
    doc(collection(editorDatabase, USERS_COLLECTION), userId)
  );
  const email = user.data()?.email;
  if (!email) {
    return false;
  }

  const adminEmailQuery = query(
    adminUsersCollection,
    where("email", "==", email)
  );
  const adminEmailSnapshot = await getDocs(adminEmailQuery);

  return adminUsersSnapshot.exists() || adminEmailSnapshot.size > 0;
};

export const fetchIsUserCreatorOfComic = async (uid: string) => {
  const comicSeriesCollection = collection(editorDatabase, "comicSeries");
  const creatorQuery = query(
    comicSeriesCollection,
    where("creatorIds", "array-contains", uid)
  );
  const querySnapshot = await getDocs(creatorQuery);
  return querySnapshot.size > 0;
};

export const updateRecentlyOpenedComics = async (
  uid: string,
  comicId: string
) => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  const userSnapshot = await getDoc(userDocRef);
  const recentlyOpenedComics = (userSnapshot.data()?.recentlyOpenedComics ||
    []) as string[];
  const index = recentlyOpenedComics.indexOf(comicId);
  if (index !== -1) {
    recentlyOpenedComics.splice(index, 1);
  }
  recentlyOpenedComics.unshift(comicId);
  await setDoc(userDocRef, { recentlyOpenedComics }, { merge: true });
};

export const fetchRecentlyOpenedComics = async (uid: string) => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  const userSnapshot = await getDoc(userDocRef);
  return userSnapshot.data()?.recentlyOpenedComics || [];
};

export const createUser = async (uid: string, email?: string) => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  const userSnapshot = await getDoc(userDocRef);
  if (userSnapshot.exists()) {
    return;
  }

  const userData = {
    email: email || "",
    displayName: email?.split("@")[0] || "user",
    createdAt: serverTimestamp(),
    recentlyOpenedComics: [],
    credits: 0,
    uid,
  };

  try {
    await setDoc(userDocRef, userData);
    console.log("User created successfully");
    return true;
  } catch (error) {
    console.error("Error creating user:", error);
    return false;
  }
};

export const fetchUserIdByEmail = async (
  email: string
): Promise<string | null> => {
  const usersCollection = collection(editorDatabase, USERS_COLLECTION);
  const q = query(usersCollection, where("email", "==", email), limit(1));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  return querySnapshot.docs[0].id;
};

export const setUserUidField = async (uid: string): Promise<void> => {
  const userDocRef = doc(editorDatabase, USERS_COLLECTION, uid);
  await setDoc(userDocRef, { uid }, { merge: true });
};

export const fetchUsersByIds = async (userIds: string[]): Promise<User[]> => {
  const usersCollection = collection(editorDatabase, USERS_COLLECTION);
  const userPromises = userIds.map(async (userId) => {
    const userDocRef = doc(usersCollection, userId);
    const userSnapshot = await getDoc(userDocRef);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data() as User;
      return { ...userData, uid: userSnapshot.id };
    }
    return null;
  });

  const users = await Promise.all(userPromises);
  return users.filter((user) => user !== null) as User[];
};
